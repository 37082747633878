(function ($) {
	"use strict";

    /* Navigation Toggle
       ========================================================================== */
	$(document).on("click", ".navigation-toggle", function (e) {
		e.preventDefault();
		dropdown.close();

		$(this).toggleClass("active");
		$("html").toggleClass("no-scroll");
		$(".menu-item-has-dropdown").removeClass("active");
		$(".navigation-inner").removeClass("no-scroll");
		$(".menu-dropdown").removeClass("dropdown-open");
		$(".sub-menu-dropdown").removeClass("sub-dropdown-open");
		$(".search-area").removeClass("search-open");
		$(".search-toggle").removeClass("active");
		$(".site-search").removeClass("active");
		$(".navigation-region").toggleClass("navigation-open");
	});

    /* Dropdown Toggle
       ========================================================================== */

	var baseUrl = $('meta[name="root-url"]').attr('content') || '/shop/';

	var dropdown = {
		__call: function (method, args) {
			if (this.states[this.state][method]) {
				return this.states[this.state][method].apply(this, args);
			}

			return null;
		},

		__next: function (nextState) {
			if (nextState in this.states) {
				this.state = nextState;
			}
		},

		state: 'closed',

		states: {
			closed: {
				open: function ($this) {
					$(".menu-item-has-dropdown").removeClass("active");
					$this.parent().addClass("active");
					$(".search-area").removeClass("search-open");
					$(".navigation-inner").addClass("no-scroll");
					$(".menu-dropdown-inner").removeClass("no-scroll");
					$(".menu-dropdown").addClass("dropdown-open");

					this.__next('open');
				}
			},
			open: {
				open: function ($this) {
					$(".menu-item-has-dropdown").removeClass("active");
					$this.parent().addClass("active");
				},

				close: function ($this) {
					if ($this) {
						$this.parent().removeClass('active');
					} else {
						$('.menu-item-has-dropdown > a, .menu-dropdown-back:not([data-category])')
							.parent().removeClass('active');
					}

					$(".menu-item-has-dropdown").removeClass("active");
					$(".navigation-inner").removeClass("no-scroll");
					$(".menu-dropdown-inner").removeClass("no-scroll");
					$(".menu-dropdown").removeClass("dropdown-open");

					this.__next('closed');
				},

				select: function ($this) {
					if ($this.attr('data-category') !== undefined) {
						var cat = $this.data('category');
						var nextUrl = baseUrl + 'menu/' + cat;
					} else if ($this.attr('data-collectors') !== undefined) {
						var cat = parseInt($this.data('collectors'));
						var nextUrl = cat === 0
							? baseUrl + 'menu/collectors'
							: baseUrl + 'menu/collectors/' + cat;
					}

					$.get(nextUrl)
						.done(function (content) {
							$('#menuDropdown').html(content);
						});
				}
			}
		},

		open: function () {
			this.__call('open', arguments);
		},

		close: function () {
			this.__call('close', arguments);
		},

		select: function () {
			this.__call('select', arguments);
		}

	};

	$(document).on("click", ".menu-item-has-dropdown > a", function (e) {
		e.preventDefault();

		var $this = $(this);

		if ($this.parent().hasClass("active")) {
			dropdown.close($this);
		} else {
			dropdown.open($this);
		}
	});

	$(document).on('click', '.site-navigation a[data-category], .site-navigation a[data-collectors]', function (e) {
		e.preventDefault();

		dropdown.select($(this));
	});

	$(document).on("click", ".menu-dropdown-back:not([data-category], [data-collectors])", function (e) {
		e.preventDefault();

		dropdown.close($(this));
	});

    /* Search Toggle
       ========================================================================== */
	$(document).on("click", ".search-toggle", function (e) {
		e.preventDefault();
		dropdown.close();

		$("html").removeClass("no-scroll");
		$(".navigation-toggle").removeClass("active");
		$(".navigation-region").removeClass("navigation-open");
		$(".menu-dropdown").removeClass("dropdown-open");
		$(".search-toggle").toggleClass("active");
		$(".site-search").toggleClass("active");
		$(".search-field").focus();
	});

	$(document).on("click", ".advanced-search-link", function (e) {
		e.preventDefault();
		dropdown.close();

		$(".menu-item-has-dropdown").removeClass("active");
		$(".navigation-inner").addClass("no-scroll");
		$(".menu-dropdown").removeClass("dropdown-open");
		$(".menu-dropdown-inner").toggleClass("no-scroll");
		$(".search-area").toggleClass("search-open");
	});

    $(document).on('click', '.site-search .search-link', function (e) {
        e.preventDefault();
        dropdown.close();

        $(".navigation-toggle").click();
        $(".advanced-search-link").click();
    });

	$(document).on("click", ".search-back", function (e) {
		e.preventDefault();
		dropdown.close();

		$(".menu-item-has-dropdown").removeClass("active");
		$(".navigation-inner").removeClass("no-scroll");
		$(".menu-dropdown").removeClass("dropdown-open");
		$(".menu-dropdown-inner").toggleClass("no-scroll");
		$(".search-area").toggleClass("search-open");
	});

    /* Site Alert
	   ========================================================================== */

	if (wdst.cookie.get('WB_Cookie_Notice')) {
		$('.js-cookie-alert').addClass('hidden');
	}

	// Set 30 day cookie when clicking the cookie alert's close button
	$('.js-cookie-alert').on('click', '.alert-close', function () {
		wdst.cookie.set('WB_Cookie_Notice', '1', (30 * 24 * 60 * 60));
	});

	$(document).on("click", ".alert-close", function (e) {
		e.preventDefault();
		$(".site-alert").addClass("alert-closed");
	});

    /* Filter Area
       ========================================================================== */
	$(document).on("click", ".products-filter, .filter-close", function (e) {
		e.preventDefault();
		$(".filter-area").toggleClass("filter-open");
		$("html").toggleClass('mobile-no-scroll');
	});

	$(document).ready(function ($) {

        /* Site Loaded
           ========================================================================== */
		$(window).on("load", function () {
			$(".site").addClass("site-loaded");
		});


        /* Category Dropdown
           ========================================================================== */

		var categoryDropdown = $('#category-dropdown');

		if (categoryDropdown.length) {
			// Push to category page on change event
			categoryDropdown.on('change', function () {
				if (this.options[this.selectedIndex].value != -1) {
					location.href = "/category/" + this.options[this.selectedIndex].value;
				}
			});

			// Set dropdown to current category if on category page.
			var category = $('.category-dropdown-wrapper').data('category');

			if (category) {
				categoryDropdown.val(category);
			}
		}

        /* Archive Dropdown
           ========================================================================== */

		var archiveDropdown = $('#archive-dropdown');

		if (archiveDropdown.length) {
			// Push to category page on change event
			archiveDropdown.on('change', function () {
				if (this.options[this.selectedIndex].value != -1) {
					location.href = this.options[this.selectedIndex].value;
				}
			});

			// Set dropdown to current page is on archive page.
			var wrapper = archiveDropdown.data('archive');

			if (wrapper) {
				var year = archiveDropdown.data('year');
				var month = archiveDropdown.data('month');

				if (month < 10) { month = '0' + month };

				var url = document.location.origin + '/' + year + '/' + month + '/';

				archiveDropdown.val(url);
			}
		}

        /* Telephone Links
           ========================================================================== */

		$(".tel[data-phone], .tel .value[data-phone]").each(function () {
			var tel = $(this);
			var link = $("<a/>");

			link.html(tel.html());
			link.attr("href", "tel:" + tel.data("phone"));
			link.attr("class", tel.attr("class"));

			tel.replaceWith(link);
		});

        /* Tabset
           ========================================================================== */

		var tabLinks = $("[data-tab]:not(.disabled)");
		var tabContents = $("[data-tab-content]");
		var tabLinkActive = ".tab-link.active";
		var tabLinkActiveClass = "active";
		var tabActiveClass = "active";

		$("[data-tabset]").each(function () {
			var wrapper = $(this);
			var group = wrapper.data("tab-group");
			var scope = "[data-tab-group=" + group + "]";
			var tabs = tabLinks.filter(scope);
			var content = tabContents.filter(scope);
			var init = wrapper.data("tab-init");

			// Init Tab

			$("[data-tab]" + scope).each(function (index, a) {
				if ($(a).attr("href") === window.location.hash) {
					// Do nothing
				}
				else {
					tabs.filter("[data-tab=" + init + "]").addClass(tabLinkActiveClass);
					content.filter("[data-tab-content=" + init + "]").addClass(tabActiveClass);
				}
			});

			// Tabs

			tabs.on("click", function (e) {
				var tab = $(this);
				var activeTab = tab.data("tab");

				// Show active
				tabs.removeClass(tabLinkActiveClass).filter("[data-tab=" + activeTab + "]").addClass(tabLinkActiveClass);
				content.removeClass(tabActiveClass).filter("[data-tab-content=" + activeTab + "]").addClass(tabActiveClass);

				if ($(window).width() < 768) {
					$(document).scrollTop($(this).offset().top);
				}
				e.preventDefault();
			});

			// Pevious Tab

			wrapper.on("click", "[data-tab-prev]" + scope, function (e) {
				for (var i = tabs.length - 1; i >= 0; i--) {
					var tab = tabs.eq(i);

					if (tab.is(tabLinkActive)) {
						tabs.eq(i - 1).trigger("click");

						break;
					}
				}
				e.preventDefault();
			});

			// Next Tab

			wrapper.on("click", "[data-tab-next]" + scope, function (e) {
				for (var i = 0, last = tabs.length; i < last; i++) {
					var tab = tabs.eq(i);

					if (tab.is(tabLinkActive)) {
						tabs.eq((i + 1) % last).trigger("click");

						break;
					}
				}
				e.preventDefault();
			});

			// Hash Tab

			$(window).on("hashchange load", function () {
				$("[data-tab]" + scope).each(function (index, a) {
					var tab = $(this);
					var activeTab = tab.data("tab");

					if ($(a).attr("href") === window.location.hash && window.location.href) {
						tabs.removeClass(tabLinkActiveClass).filter("[data-tab=" + activeTab + "]").addClass(tabLinkActiveClass);
						content.removeClass(tabActiveClass).filter("[data-tab-content=" + activeTab + "]").addClass(tabActiveClass);
						$(document).scrollTop($(this).offset().top);
					}
				});
			});
		});

        /* Accordion
           ========================================================================== */

		var accordion = "[data-accordion]";
		var accordionHeader = "[data-accordion-header]";
		var accordionContent = "[data-accordion-content]";
		var accordionActiveClass = "active";

		$(accordion)
			.find(accordionContent).hide().end()
			.find(accordionHeader + ":first").addClass(accordionActiveClass).end()
			.find(accordionContent + ":first").show().end()
			.on("click", accordionHeader, function (e) {
				var self = $(this);
				var accordionBody = self.closest(accordion);

				if (!self.hasClass(accordionActiveClass)) {
					accordionBody
						.find(accordionHeader).removeClass(accordionActiveClass).end()
						.find(accordionContent).slideUp().end();

					self.addClass(accordionActiveClass)
						.next(accordionContent).slideDown(function () {
							if ($(window).width() < 768) {
								$(document).scrollTop(self.offset().top);
							}
						}).end();
				}
				e.preventDefault();
			});

        /* Video Overlay
           ========================================================================== */

		$(".video-overlay[data-video-id]").one("click", function () {
			$(this).html("<iframe src=\"https://www.youtube.com/embed/" + $(this).data("video-id") + "?enablejsapi=1&autoplay=1&rel=0\" allowfullscreen></iframe>");
		});

        /* HTML5 Placeholder
           ========================================================================== */

		$("input, textarea").placeholder();

        /* Slick Slider
           ========================================================================== */

		$("[data-slick]").slick();

        /* Fancybox
           ========================================================================== */

		// Gallery

		$(".gallery-thumbnail a").fancybox({
			margin: [44, 0],
			gutter: 32,
			caption: function () {
				return $(this).parent().parent().find("figcaption").html();
			}
		});

	}); // end.ready

}(jQuery));
